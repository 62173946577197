import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Icon,
  Divider,
} from 'semantic-ui-react';

import * as actions from '../../actions';
import { selectFirebaseAuthResults, selectLoginResults } from '../../selectors';
import { SignUpLink, ForgotPasswordLink } from '../Links';
import { ROUTE_PATH } from '../../constants';

const STYLES = {
  CONTAINER: {
    OTHERS: {
      paddingTop: '4em',
    },
    MOBILE: {
      paddingTop: '1em',
    },
  },
  GRID: {
    INDEX: {
      height: '100%',
    },
    COLUMN: {
      width: '500px',
    },
  },
};

const Login = (props) => {
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const {
    // authUserUsingFacebook,
    authUserUsingGoogle,
    authed,
    browser,
    isFetching,
    loginUser,
    message,
  } = props;

  const isMobile = browser.lessThan.medium;

  if (authed) {
    // FIXME: redirect to previous visited page
    // const routerLocationState = router.location.state;
    // if (routerLocationState && routerLocationState.from) {
    //   return (
    //     <Redirect
    //       to={{
    //         pathname: routerLocationState.from,
    //         state: {
    //           authedAndLoggedIn: true,
    //         },
    //       }}
    //     />
    //   );
    // }
    router.push(ROUTE_PATH.HOME);
  }

  return (
    <div style={isMobile ? STYLES.CONTAINER.MOBILE : STYLES.CONTAINER.OTHERS}>
      <Grid textAlign="center" verticalAlign="middle" style={STYLES.GRID.INDEX}>
        <Grid.Column style={STYLES.GRID.COLUMN}>
          <Header as="h2" color="black" textAlign="center">
            登入
          </Header>
          <Form size="large" warning={message !== null}>
            <Segment>
              <Form.Input
                fluid
                autoComplete="email"
                name="email"
                icon="mail"
                iconPosition="left"
                placeholder="信箱地址"
                value={email}
                onChange={(e, { name, value }) => setEmail(value)}
              />
              <Form.Input
                fluid
                autoComplete="password"
                name="password"
                icon="lock"
                iconPosition="left"
                placeholder="密碼"
                type="password"
                value={password}
                onChange={(e, { name, value }) => setPassword(value)}
              />
              <Message warning header={message} />
              <Form.Field>
                <ForgotPasswordLink>忘記密碼？</ForgotPasswordLink>
              </Form.Field>
              <Button
                fluid
                inverted
                color="green"
                size="large"
                disabled={!email || !password}
                loading={isFetching}
                onClick={() => loginUser({
                  email,
                  password,
                })}
              >
                送出 ！！
              </Button>
              <Divider horizontal>
                <Header as="h2" color="grey">
                  OR
                </Header>
              </Divider>
              <Button
                color="google plus"
                onClick={() => authUserUsingGoogle(isMobile)}
                fluid
              >
                <Icon name="google" />
                登入使用 Google
              </Button>
            </Segment>
          </Form>
          <Message>
            還沒加入? <SignUpLink>註冊</SignUpLink>
          </Message>
          <p style={{ marginTop: 30 }}>登入後代表你已滿法定年齡</p>
        </Grid.Column>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  ...selectFirebaseAuthResults(state),
  ...selectLoginResults(state),
  browser: state.browser,
  router: state.router,
});

export default connect(mapStateToProps, actions)(Login);
